<template>
  <div
    ref="rootEl"
    class="duo-picture pt-xxxl"
    :class="[
      content.background_color && `bg-${content.background_color}`,
      { ribbon: content.ribbon },
    ]"
  >
    <div
      v-if="content.title"
      ref="title"
      class="duo-picture__title"
      :class="content.titleColor"
      v-html="content.title"
    ></div>

    <div
      v-if="content.ribbon && content.picture"
      class="duo-picture__ribbon-outer"
      :class="`bg-${content.ribbon_color || 'cobalt'}`"
    >
      <img
        ref="imgEl"
        v-src="content.picture"
        class="duo-picture__ribbon"
        :alt="content.picture.alt"
      />
    </div>

    <div
      v-else-if="content.picture"
      ref="picCtn"
      class="duo-picture__picture-outer"
    >
      <img
        ref="imgEl"
        v-src="content.picture"
        class="duo-picture__picture"
        :alt="content.picture.alt"
        sizes="80vw"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'
import type { Picture } from '@/types'

export interface DuoColoredPicture {
  background_color: string
  title: string
  titleColor: string
  ribbon?: Picture
  ribbon_color?: string
  picture?: Picture
}

defineProps({
  content: {
    type: Object as PropType<DuoColoredPicture>,
    required: true,
  },
})

const rootEl = ref<HTMLElement | null>(null)
const imgEl = ref<HTMLElement | null>(null)

const appear = () => {
  if (!rootEl.value) {
    return
  }

  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set([rootEl.value, imgEl.value], { clearProps: 'all' })
    },
  })

  tl.fromTo(
    rootEl.value,
    { opacity: 0 },
    { opacity: 1, duration: 0.8, ease: '8020' }
  )

  if (imgEl.value) {
    tl.fromTo(
      imgEl.value,
      { scale: 1.05 },
      { scale: 1, duration: 0.8, ease: '8020' },
      0
    )
  }
}

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(rootEl.value, () => {
    appear()
  })
})
</script>

<style lang="scss" scoped>
$bp: m;

.duo-picture {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  overflow: hidden;
  min-height: 47.5rem;
  padding-right: col(1);
  padding-left: col(1);
  background: $c-secondary;

  &[class*='bg-'] {
    background: var(--c-accent-bg);

    &.bg-red-gradient {
      /* stylelint-disable-next-line declaration-no-important */
      background: linear-gradient(149deg, #f72717, #ff007d) !important;
    }
  }

  .white-papers & {
    .visible & {
      top: var(--header-height);
      height: calc(100vh - var(--header-height));
    }

    @include mq($bp) {
      position: sticky;
      top: 0;
      height: 100vh;
    }
  }

  &.ribbon {
    min-height: auto;
  }

  @include mq($until: $bp) {
    padding-top: 4rem;
  }

  @include mq($bp) {
    padding-right: col(1, var(--available-columns));
    padding-left: col(1, var(--available-columns));
  }
}

.duo-picture__title {
  position: relative;
  z-index: 1;

  &.white {
    color: $c-white;
  }

  @include mq(xl) {
    margin-right: col(1, 7);
    margin-left: col(1, 7);
  }
}

.duo-picture__ribbon-outer {
  display: block;
  overflow: hidden;
  width: fit-content;
  height: 30rem;
  margin: $spacing * 2 auto 0;
  background: var(--c-accent-30);
  border-top-left-radius: 50rem;
  border-top-right-radius: 50rem;

  @include mq($bp) {
    width: 100%;
    height: auto;
    margin-top: $spacing * 3;
  }

  @include mq(xl) {
    margin-top: $spacing * 4;
  }
}

.duo-picture__ribbon {
  display: block;
  height: 100%;
  object-fit: cover;

  @include mq($bp) {
    width: 140%;
    height: auto;
  }
}

.duo-picture__picture-outer {
  @include get-all-space;

  transform-origin: bottom center;
}

.duo-picture__picture {
  @include image-fit;

  inset: initial;
  right: 0;
  bottom: 0;
}
</style>
